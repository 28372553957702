@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        font-family: "Manrope", sans-serif;
        font-size: 16px;
        color: #696969;
        font-weight: 400;
        box-sizing: border-box;
    }
    input,
    button,
    textarea,
    select {
        font-family: "Manrope", sans-serif;
        outline: none;
    }
    .slider_width {
        width: calc((100% / 3) - 16px);
    }
    .show_dots {
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .text-shadow-no1 {
        text-shadow: 1px 1px 0 #4a90e2, -1px 1px 0 #4a90e2, -1px -1px 0 #4a90e2,
            1px -1px 0 #4a90e2;
    }
    .text-shadow-no2 {
        text-shadow: 1px 1px 0 #50e3c2, -1px 1px 0 #50e3c2, -1px -1px 0 #50e3c2,
            1px -1px 0 #50e3c2;
    }
    .text-shadow-no3 {
        text-shadow: 1px 1px 0 #e35050, -1px 1px 0 #e35050, -1px -1px 0 #e35050,
            1px -1px 0 #e35050;
    }
    .text-shadow-rest {
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5), -1px 1px 0 rgba(0, 0, 0, 0.5),
            -1px -1px 0 rgba(0, 0, 0, 0.5), 1px -1px 0 rgba(0, 0, 0, 0.5);
    }
}
.slick-next:before,
.slick-prev:before {
    font-size: 38px !important;
}

.slick-next {
    right: -15px !important;
    top: 30% !important;
}

.slick-prev {
    left: -30px !important;
    top: 30% !important;
}
